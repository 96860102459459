<template>
    <div class="auth-box__col">
        <div class="validation-wrap">
            <form class="auth-box__form" novalidate="novalidate">
                <div class="auth-box__title auth-box__title_main">{{$t("modals.new_user")}}</div>
                <div class="form-group">
                    <label class="control-label" for="field-reg-name">{{$t("modals.name")}}</label>
                    <input
                        v-model="formData.name"
                        class="form-control" id="field-reg-name" type="text"
                        :placeholder="$t('modals.name_placeholder')" required="required"/>

                    <div
                        v-if="errors.name"
                        v-html="errors.name.join('<br>')"
                        class="input-field-error"></div>
                </div>
                <div
                    v-if="errors.phone"
                    v-html="errors.phone.join('<br>')"
                    class="input-field-error"></div>
                <div class="form-group">
                    <label class="control-label" for="field-reg-email">E-mail</label>
                    <input
                        v-model="formData.email"
                        class="form-control" id="field-reg-email" type="email"
                        :placeholder="$t('modals.email_placeholder')" required="required"/>

                    <div
                        v-if="errors.email"
                        v-html="errors.email.join('<br>')"
                        class="input-field-error"></div>
                </div>
                <div class="form-group">
                    <div class="password-field">
                        <label class="control-label" for="field-reg-password">
                            {{$t("modals.password")}}
                        </label>
                        <input
                            v-model="formData.password"
                            class="form-control" id="field-reg-password" type="password"
                            :placeholder="$t('modals.password_placeholder')" required="required"/>
                        <div class="password-field-toggle"></div>
                    </div>
                    <div
                        v-if="errors.password"
                        v-html="errors.password.join('<br>')"
                        class="input-field-error"></div>
                </div>
                <div class="form-group">
                    <div class="password-field">
                        <label class="control-label" for="field-reg-password-repeat">
                            {{$t("modals.password_repeat")}}</label>
                        <input
                            v-model="formData.password_confirmation"
                            class="form-control"
                            id="field-reg-password-repeat"
                            type="password"
                            :placeholder="$t('modals.password_repeat_placeholder')"
                            required="required"/>
                        <div class="password-field-toggle"></div>
                    </div>
                    <div
                        v-if="errors.password_confirmation"
                        v-html="errors.password_confirmation.join('<br>')"
                        class="input-field-error"></div>
                </div>
                <div class="auth-box__form-check">
                    <div class="auth-box__form-check-item">
                        <div class="checkbox">
                            <label>
                                <input
                                    v-model="formData.user_agreement"
                                    type="checkbox" required="required" value="true"/>
                                <span v-html="$t('cabinet.agreement')"></span>
                            </label>
                        </div>
                        <div
                            v-if="errors.user_agreement"
                            v-html="errors.user_agreement.join('<br>')"
                            class="input-field-error"></div>
                    </div>
<!--                    <div class="auth-box__form-check-item">-->
<!--                        <div class="checkbox">-->
<!--                            <label>-->
<!--                                <input type="checkbox" required="required"/>-->
<!--                                <span>Я не робот</span>-->
<!--                            </label>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
                <div class="auth-box__form-footer">
                    <button
                        @click.stop.prevent="registration"
                        class="auth-box__form-button btn btn-link" type="submit">{{$t("modals.register_button")}}
                        <span class="auth-box__form-button-icon icon-arrow-right-2"></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Registration",
        data: () => ({
            errors: {},
            errorMessage: '',
            formData: {
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
                user_agreement: null,
                recaptcha: null,
            }
        }),
        methods: {
            registration() {
                let data = this.formData
                this.errors = null
                if (data.user_agreement !== true)
                    delete data.user_agreement

                axios.post('/user/register', data)
                    .then(function (response) {
                        if (response.data.status === 'success')
                            location.href += 'cabinet'
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors;
                        this.errorMessage = error.response.data.message;
                    })
            }
        }
    }
</script>
