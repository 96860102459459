<template>
    <div class="auth-box__col active">
        <div class="auth-box__title auth-box__title_main">{{$t("modals.auth")}}</div>
<!--        <div class="social-auth">-->
<!--            <div class="social-auth__title">Увійти без реєстрації через соціальну мержу</div>-->
<!--            <div class="social-auth__list">-->
<!--                <div class="social-auth__list-item">-->
<!--                    <a class="social-auth__list-link icon-facebook" href="/"></a>-->
<!--                </div>-->
<!--                <div class="social-auth__list-item">-->
<!--                    <a class="social-auth__list-link icon-twitter" href="/"></a>-->
<!--                </div>-->
<!--                <div class="social-auth__list-item">-->
<!--                    <a class="social-auth__list-link icon-google" href="/"></a>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="validation-wrap">
            <form class="auth-box__form post-form" novalidate="novalidate">
                <div class="auth-box__title" v-html="$t('modals.auth_text')"></div>
                <div class="form-group">
                    <label class="control-label" for="field-auth-email">E-mail</label>
                    <input
                        v-model="formData.email"
                        class="form-control" id="field-auth-email" type="email"
                        :placeholder="$t('modals.email_placeholder')" required="required"/>
                    <div
                        v-if="errors.email"
                        v-html="errors.email.join('<br>')"
                        class="input-field-error"></div>
                </div>
                <div class="form-group">
                    <div class="password-field">
                        <label class="control-label" for="field-auth-password">{{$t("modals.password")}}</label>
                        <input
                            v-model="formData.password"
                            class="form-control" id="field-auth-password" type="password"
                            :placeholder="$t('modals.password_placeholder')" required="required"/>
                        <div class="password-field-toggle"></div>
                    </div>
                    <div
                        v-if="errors.password"
                        v-html="errors.password.join('<br>')"
                        class="input-field-error"></div>
                </div>
                <div class="auth-box__form-check">
                    <div class="auth-box__form-check-row">
                        <div class="checkbox">
                            <label>
                                <input
                                    v-model="formData.remember"
                                    value="true"
                                    type="checkbox"/>
                                <span>{{$t("modals.remember")}}</span>
                            </label>
                        </div>
                        <a class="auth-box__reset-password" href="#modal-reset-password"
                           data-toggle="modal" data-dismiss="modal">{{$t("modals.restore_password")}}</a>
                    </div>
                </div>
                <div class="auth-box__form-footer">
                    <button
                        @click.prevent="login"
                        class="auth-box__form-button btn btn-link" type="submit">{{$t("modals.enter")}}
                        <span class="auth-box__form-button-icon icon-arrow-right-2"></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import getLocaleFromUrl from "../../../functions.js"
    export default {
        name: "Authorization",
        data: () => ({
            errors: {},
            errorMessage: '',
            formData: {
                email: '',
                password: '',
                remember: false
            }
        }),
        methods: {
            login() {
                this.errors = null
                axios.post('/user/login', this.formData)
                    .then(function (response) {
                        if (response.data.status === 'success')
                            location.href = '/'+getLocaleFromUrl()+'/cabinet'
                        
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors;
                        this.errorMessage = error.response.data.message;
                    })
            }
        }
    }
</script>
