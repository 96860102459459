/**
 * Рахує суму продукту з урахуванням цін опцій
 * @param product Продукт для якого порахувати суму
 */
function getItemSum(product) {
    let sum = parseFloat(product.price)
    for (let i in product.options) {
        if (product.options[i].price > 0)
            sum += parseFloat(product.options[i].price)
        //Коли не product.options[i].selected то це динамічна опція не вибрана. наприклад "Бірка"
        if (product.options[i].selected && product.options[i].selected.price > 0)
            sum += parseFloat(product.options[i].selected.price)
    }
    return sum.toFixed(2)
}

/**
 * Повертає повну суму продукту з урахуванням опцій та кількості штук
 * @param product {object} Продукт для якого порахувати суму
 */
function getItemSumTotal(product) {
    return (parseFloat(getItemSum(product) * product.qnt)).toFixed(2)
}

/**
 * Повертає суму всіх продуктів в корзині враховуючи їх кількість
 */
function getTotalPrice() {
    let total = 0.00
    for (let i in store.getters.getCartProducts) {
        total += parseFloat(getItemSumTotal(store.getters.getCartProducts[i]))
    }


    return total.toFixed(2)
}

/**
 * Повертає повну суму із скидками тапромокодами
 */

function getTotalSum() {
    let total = getTotalPrice()
    //Сума скидок  і тип у доставки
    total = total - store.getters.getDiscountSum
    // PromoCode
    if (store.getters.getPromocode) {
        let promo = store.getters.getPromocode;
        if (total > promo.sum_from) {
            if (promo.type === 'percent') {
                total = total - (parseFloat(total) * parseFloat('0.' + promo.value))
            }
            if (promo.type === 'sum')
                total = total - promo.value
        }
    }
    total += store.getters.getOrderCountry.price;
    return total.toFixed(2)
}

/**
 * Розбиваємо масив на підмасиви по кількості елементів
 * @param array
 * @param chunkSize
 * @return {*[]}
 */
function chunk(array, chunkSize) {
    let length = Math.ceil(array.length / chunkSize)
    let ch = [].concat.apply([],
        array.map(function (elem, i) {
            return i % length ? [] : [array.slice(i, i + length)];
        })
    );

    return ch
}


export default {
    install: function (Vue) {
        Vue.prototype.getItemSum = (product) => getItemSum(product)
        Vue.prototype.getItemSumTotal = (product) => getItemSumTotal(product)
        Vue.prototype.getTotalPrice = () => getTotalPrice()
        Vue.prototype.getTotalSum = () => getTotalSum()
        Vue.prototype.chunk = (array, chunkSize) => chunk(array, chunkSize)
    }
}
