<template>
    <div class="constructor-box__footer">
        <div v-if="openErrors" class="constructor-box__footer-inner error-valid">
            <span v-if="errors.length > 1">{{$t("cart.fields_empty")}} {{errors.join(', ')}} {{$t("cart.fields_empty_text")}}</span>
            <span v-else>{{$t("cart.field_empty")}} {{errors.join(', ')}} {{$t("cart.field_empty_text")}}</span>

        </div>
        <div class="constructor-box__footer-inner">
            <div
                v-if="getPrice"
                class="constructor-box__price">
                <div class="constructor-box__price-label">{{$t("cart.price")}}:</div>
                <div class="constructor-box__price-value">{{getPrice}} ₴</div>
                <div class="product-item__sum-dollar" v-if="$i18n.locale != 'uk' ">( ~ {{ToDollar(getPrice)}} USD)</div>
            </div>
            <div
                class="constructor-box__footer-info">
                <div class="constructor-box__footer-info-label">{{$t("cart.preparation_time")}}:</div>
                <div class="constructor-box__footer-info-value">{{store.getters.getMockUp.preparation_time}} {{$t("cart.days")}}
                </div>
            </div>
            <div class="constructor-box__footer-wrap">
                <a
                    v-if="store.getters.getActiveStep !== 'Checkout'"
                    @click.prevent="nextStep"
                    class="constructor-box__footer-next" href="#next">
                    <span class="constructor-box__footer-next-label">{{$t("cart.next")}}</span>
                    <span class="constructor-box__footer-next-icon icon-arrow-right-2"></span>
                </a>
                <a v-else
                   @click.prevent="addToCart"
                   class="constructor-box__footer-button" href="/">
                    <span class="constructor-box__footer-button-label" v-html="$t('cart.add_to_cart')"></span>
                    <span class="constructor-box__footer-button-icon icon-cart"></span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

let match = window.location.href.split("/")[3];

    export default {
        name: "CartPanel",
        data: () => ({
            store: store,
            openErrors: false,
            errors: [],
        }),
        methods: {
            nextStep() {
                if (!this.validate())
                    return false

                let next = false
                for (let comp in store.getters.getSteps) {
                    if (comp === store.getters.getActiveStep)
                        next = true
                    else if (next === true)
                        return store.commit('setActiveStep', comp)
                }
            },
            ToDollar(sum){
                let rate = store.getters.getExchangeRate;
   
                return (sum/rate).toFixed(2);
            },
            addToCart() {
                if (!this.validate())
                    return false

                store.commit('addToCart')
            },
            toggleErrors() {
                this.openErrors = true
                setTimeout(() => this.openErrors = false, 4000)
            },
            validate() {
                let options = store.getters.getOptionsByStep[store.getters.getActiveStep],
                    selectedOptions = store.getters.getSelectedParamsBySteps[store.getters.getActiveStep]
                this.errors = []

                for (let i in options) {

                    if (options[i].required && !selectedOptions[options[i].slug]) {
                        if (options[i].type === 'map' || store.getters.getThereImagesForOption[options[i].slug] === true)
                            this.errors.push('"' + options[i].title + '"')
                    }

                    if (options[i].type === 'colors') {
                        for (let k in options[i].children) {
                            if (options[i].children[k].required && !selectedOptions[options[i].children[k].slug])
                                this.errors.push('"' + options[i].children[k].title + '"')
                        }
                    }
                }

                // Костиль перевірки динамічної опції
                if (store.getters.getActiveStep === 'Checkout') {
                    if (!selectedOptions['size'] && store.getters.getMockUp.type === 'shirts')
                        this.errors.push((match == 'en') ? '"Size"' : '"Розмір"')
                }

                if (this.errors.length > 0) {
                    this.toggleErrors()
                    return false
                }

                return true
            },
        },
        computed: {
            getPrice() {
                let price = parseFloat(store.getters.getMockUp.price)
                let param = store.getters.getSelectedParams
                let selectImg = false // Показувати ціну після вибору в другому кроці опцію із зображенням
                for (let key in param) {
                    if (store.getters.getOptionsBySlug[key] && store.getters.getOptionsBySlug[key].price) {
                        price += parseFloat(store.getters.getOptionsBySlug[key].price)

                        if (store.getters.getOptionsBySlug[key].type === 'images')
                            selectImg = true
                    }
                    if (typeof param[key] === 'string') {
                        if (store.getters.getOptionsBySlug[param[key]] && store.getters.getOptionsBySlug[param[key]].price)
                            price += parseFloat(store.getters.getOptionsBySlug[param[key]].price)
                    } else if (param[key].price) {
                        price += parseFloat(param[key].price)
                    }
                }
                return selectImg ? price : 0
            },
        }
    }
</script>

<style scoped>

</style>
