
function getLocaleFromUrl() {
    let match = window.location.href.split("/")[3];
    if (match === '') {
        match = "uk";
    }
    return match;
}


export default getLocaleFromUrl;