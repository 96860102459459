import Vue from 'vue'
import Vuex from 'vuex'
import VueI18n from 'vue-i18n'
import MockupStore from "./index/store/mockup"
import CartStore from "./index/store/cart"
import AuthPopUp from './index/user/AuthPopUp'
import ForgotPassword from './index/user/ForgotPassword'
import ResetPassword from './index/user/ResetPassword'
import Cart from "./index/components/Cart"
import CartPanel from "./index/components/CartPanel"
import ProductManipulation from './index/classes/ProductManipulation'
import ProductComponentsSelection from "./index/components/ProductComponentsSelection";


import messagesEn from '../../lang/vue-lang/en.json';
import messagesUk from '../../lang/vue-lang/uk.json';


Vue.use(Vuex)
Vue.use(VueI18n)
window.axios = require('axios')
// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")
    config.headers.Authorization = csrfToken

    return config
})

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

function getLocaleFromUrl() {
    // let matches = window.location.href.match("/(?<=/)([a-z]+)(?=/)/");(?:\/)([a-z]+)(?=\/)
    let match = window.location.href.split("/")[3];
    if (match === '') {
        match = "uk";
    }
    //console.log(match);
    return match;
}


function loadTranslations() {
    let locale = getLocaleFromUrl();
    return axios.post(`/api/get-translations/${locale}`)
        .then(response => {
            return response.data;
        });
}


//import LangStore from "./lang/store/lang"

window.store = new Vuex.Store({
    modules: {
        MockupStore,
        CartStore
    }
})

let messages = {};
// await loadTranslations().then((response) => {
//     messages = response;
// });

window.i18n = new VueI18n({
    locale: getLocaleFromUrl(), // set locale

    messages: {
        en: messagesEn,
        uk: messagesUk,

    }


    // messages, // set locale messages
})

Vue.use(ProductManipulation)

// window.i18n = new VueI18n({
//     locale: getLocaleFromUrl(), // set locale
//     // messages, // set locale messages
// })


const app = new Vue({
    el: '#app-constructor',
    store: store,
    i18n: i18n,
    components: {
        Cart,
        CartPanel,
        AuthPopUp,
        ResetPassword,
        ForgotPassword,
        ProductComponentsSelection
    }
});


