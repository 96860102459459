<template>
    <div class="modal fade modal-xs" id="modal-reset-password" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <button class="modal-close icon-close close" data-dismiss="modal" type="button"></button>
                <div class="reset-password">
                    <div class="reset-password__header">
                        <div class="reset-password__title">{{ $t('modals.Відновлення паролю') }}</div>
                        <div class="reset-password__text">{{ $t('modals.Що б відновити пароль введіть адресу своєї електронної пошти')}}</div>
                    </div>
                    <div class="validation-wrap">
                        <form class="reset-password__form" novalidate="novalidate">
                            <div class="form-group">
                                <label class="control-label" for="field-reset-password-email">E-mail</label>
                                <input
                                    v-model="formData.email"
                                    class="form-control" id="field-reset-password-email" type="email"
                                    :placeholder="$t('modals.email_placeholder')" required="required"/>
                                <div
                                    v-if="errors.email"
                                    v-html="errors.email.join('<br>')"
                                    class="input-field-error">
                                </div>
                            </div>
                            <div v-if="successMessage"
                                 v-html="successMessage"
                                 class="response-success-text"></div>
                            <div
                                v-else
                                class="reset-password__form-footer">
                                <button
                                    @click.prevent="forgot"
                                    class="reset-password__form-button btn btn-link" type="submit">{{ $t('modals.Надіслати')}}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ForgotPassword",
        data: () => ({
            errors: {},
            successMessage: '',
            formData: {
                email: ''
            }
        }),
        methods: {
            forgot() {
                this.errors = {}
                this.successMessage = ''
                axios.post('/user/password/email', this.formData)
                    .then(response => {
                        if (response.data.status === 'success') {
                            this.successMessage = response.data.message;
                        } else {
                            this.errors = response.data.errors;

                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.errors = error.response.data.errors;
                        this.errorMessage = error.response.data.message;
                    })
            }
        }
    }
</script>
