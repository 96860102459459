<template>
    <div class="modal fade modal-lg" id="modal-auth" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <button class="modal-close icon-close close" data-dismiss="modal" type="button"></button>
                <div class="auth-box">
                    <div class="auth-box__nav">
                        <div class="auth-box__nav-item auth-box__nav-item_auth active">{{$t("modals.auth")}}</div>
                        <div class="auth-box__nav-item auth-box__nav-item_reg">{{$t("modals.register")}}</div>
                    </div>
                    <div class="auth-box__body">
                        <Authorization></Authorization>
                        <Registration></Registration>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Authorization from "./inc/Authorization";
    import Registration from "./inc/Registration";

    export default {
        name: "AuthPopUp",
        components: {
            Authorization,
            Registration
        }
    }
</script>
