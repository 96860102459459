<template>
    <div class="reset-password-form">
        <div class="validation-wrap">
            <form class="auth-box__form" novalidate="novalidate">
                <div class="auth-box__title ">{{ $t('modals.Новий пароль')}}</div>
                <div
                    v-if="errorMessage"
                    v-html="'!!! ' + errorMessage"
                    class="auth-box__title response-error-text"></div>
                <div class="form-group">
                    <label class="control-label" for="field-reg-email">E-mail</label>
                    <input
                        v-model="formData.email"
                        class="form-control" id="field-reg-email" type="email"
                        :placeholder="$t('modals.email_placeholder')" required="required"/>

                    <div
                        v-if="errors.email"
                        v-html="errors.email.join('<br>')"
                        class="input-field-error"></div>
                </div>
                <div class="form-group">
                    <div class="password-field">
                        <label class="control-label" for="field-reg-password">
                            {{ $t('modals.password')}}
                        </label>
                        <input
                            v-model="formData.password"
                            class="form-control" id="field-reg-password" type="password"
                            :placeholder="$t('modals.password_placeholder')" required="required"/>
                        <div class="password-field-toggle"></div>
                    </div>
                    <div
                        v-if="errors.password"
                        v-html="errors.password.join('<br>')"
                        class="input-field-error"></div>
                </div>
                <div class="form-group">
                    <div class="password-field">
                        <label class="control-label" for="field-reg-password-repeat">
                            {{ $t('modals.password_repeat')  }}</label>
                        <input
                            v-model="formData.password_confirmation"
                            class="form-control"
                            id="field-reg-password-repeat"
                            type="password"
                            :placeholder="$t('modals.password_placeholder')"
                            required="required"/>
                        <div class="password-field-toggle"></div>
                    </div>
                    <div
                        v-if="errors.password_confirmation"
                        v-html="errors.password_confirmation.join('<br>')"
                        class="input-field-error"></div>
                </div>
                <div v-if="successMessage"
                     v-html="successMessage"
                     class="response-success-text"></div>
                <div
                    v-else
                >
                    <button
                        @click.stop.prevent="reset"
                        class="auth-box__form-button btn btn-link" type="submit">{{ $t('modals.Змінити') }}
                        <span class="auth-box__form-button-icon icon-arrow-right-2"></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ResetPassword",
        props: ['token'],
        data: () => ({
            errors: {},
            errorMessage: '',
            successMessage: '',
            formData: {
                email: '',
                password: '',
                password_confirmation: ''
            }
        }),
        methods: {
            reset() {
                let data = this.formData
                data.token = this.token
                this.errors = {}

                axios.post('/user/password/reset', data)
                    .then(response =>  {
                        if (response.data.status === 'success')
                           return  this.successMessage = response.data.message

                        this.errors = response.data.errors;
                        this.errorMessage = response.data.message;
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors;
                        this.errorMessage = error.response.data.message;
                    })
            }
        }
    }
</script>

<style scoped>

</style>
