let match = window.location.href.split("/")[3];


export default {
    state: {
        mockup: {},
        urlParams: {}, //GETпараметри url рядка
        cutColor: null,
        defaultCutColor: 'WH',//Базовиий колір мокапу
        colorTypeTitle: {
            Cut: (match == 'en') ? 'The color of linen' : 'Колір льону',
            Embroidery: (match == 'en') ? 'Embroidery color' : 'Колір вишивки',
        },
        embroideryColor: null,
        mockupsList: [],//Список доступних мокапів
        activeStep: 'Cut',
        selectedParams: {}, //selected params all steps
        selectedParamsBySteps: {
            Cut: {},
            Embroidery: {},
            Checkout: {}
        },
        activeTab: '',//Щоб бів відкритий тільки один таб для вибору
        steps: {
            Cut: (match == 'en') ? 'Cut' : 'Крій',
            Embroidery: (match == 'en') ? 'Embroidery' : 'Вишивка',
            Checkout: (match == 'en') ? 'Checkout' : 'Замовлення'
        },
        editKey: null, //Ключ продукту в масиві корзини
        thereImagesForOption: {},// Для яких опцій доступні зображння для вибору
        translations:[],
        bimp_uuid: '8f298496-431e-4b35-8813-3eec7d347637',
        productId: null,
    },
    getters: {
        getThereImagesForOption(state, param) {
            return state.thereImagesForOption
        },
        getActiveTab(state) {
            return state.activeTab
        },
        getEditKey(state) {
            return state.editKey
        },
        getColorTypeTitle(state) {
            return state.colorTypeTitle
        },
        getMockUpImg(state) {
            if (!state.selectedParamsBySteps['Cut'])
                return ''

            let cutParam = state.selectedParamsBySteps['Cut']
            let step = 'cut'
            let src = '/storage/' + state.mockup.img_dir + step + '/';
            //В масиві в разнобой, тому перебираємо
            let filenameCodes = []
            for (let key of Object.keys(cutParam)) {
                if (cutParam[key] !== null)
                    filenameCodes.push(store.getters.getOptionsBySlug[cutParam[key]].code)
            }
            if (state.cutColor) {
                filenameCodes.push(state.cutColor.code_option)
            } else
                filenameCodes.push(state.defaultCutColor)

            //При загрузці проекту вертає тільки колір без параметрів, тому чакаємо коли загрузяться праметри
            if (filenameCodes.length < 2)
                return ''

            src += filenameCodes.join('_') + '.png'

            return src
        },
        getSweepImg(state) {
            let sweepName = state.mockup.start_sweep_name

            if (!state.selectedParams[sweepName] || typeof state.selectedParams[sweepName] !== 'string')
                return ''

            let imageNameCode = store.getters.getOptionsBySlug[state.selectedParams[sweepName]].code

            if (typeof imageNameCode !== 'string')
                return ''
            let src = '/storage/' + state.mockup.img_dir + 'sweep/' + imageNameCode + '_';
            src += (state.cutColor ? state.cutColor.code_option : 'WH') + '.png'

            return src
        },
        getOptionsBySlug(store) {
            let options = []
            for (let step in store.mockup.optionsByStep) {
                for (let op of store.mockup.optionsByStep[step]) {
                    options[op.slug] = op
                    if (op.children) {
                        for (let ch in op.children) {
                            if (op.children[ch].type !== 'images' && op.children[ch].type !== 'map')
                                options[op.children[ch].slug] = op.children[ch]
                        }
                    }

                }
            }
            return options
        },
        getMockupsList(store) {
            return store.mockupsList
        },
        getEmbroideryColor(store) {
            return store.embroideryColor
        },
        getCutColor(store) {
            return store.cutColor
        },
        getSteps(store) {
            return store.steps
        },
        getOptionsByStep(state) {
            return state.mockup.optionsByStep
        },
        getActiveStep(state) {
            return state.activeStep
        },
        getMockUp(state) {
            return state.mockup
        },
        getSelectedParamsBySteps(state) {
            return state.selectedParamsBySteps
        },
        getSelectedParams(state) {
            return state.selectedParams
        },
        getUrlParams(state) {
            return state.urlParams
        },
        getColorLength() {//Кількість кольорів у вибраному зображенні основнох опції
            let length = 1,
                inheritColorOption = store.getters.getSelectedParams[store.getters.getMockUp.inherit_color]
            if (inheritColorOption && inheritColorOption.file_name) {
                length = inheritColorOption.file_name.split('.')[0].split('_').pop().length
            }

            return length
        },
        /**
         * Отримати зображення превью для опції
         * @returns {[]}
         */
        getUrlImageVariantUrls() {
            let urls = [],
                selParams = store.getters.getSelectedParamsBySteps['Embroidery']
            for (let parentKey in selParams) {
                //Прийшлося зліпити два методи, щоб перенести функціонал в store
                if (selParams[parentKey] && selParams[parentKey].dir && selParams[parentKey].type !== 'colors') {
                    let img = selParams[parentKey],
                        name = img.file_name,
                        extension = name.split('.').pop(),
                        ex = img.file_name.split('.')[0].split('_')

                    // Якщо у нас в превью префікс через дифіси, це означає, що це превью належть до декількох накладок.
                    //І ми дивимося з яких опцій (name_from_options) формується назва накладки. Префікс опції фрмується тільки з опцій крою
                    if (parentKey !== undefined && store.getters.getOptionsBySlug[parentKey]) {
                        let nameFromOptions = store.getters.getOptionsBySlug[parentKey].name_from_options

                        if (typeof nameFromOptions === 'object') {
                            let prefixCode = nameFromOptions.map(el => {
                                return store.getters.getSelectedParamsBySteps.Cut[el] ? store.getters.getOptionsBySlug[store.getters.getSelectedParamsBySteps.Cut[el]].code : ''
                            }).join('')

                            if (prefixCode.length > 0) {
                                // console.log(  'rename image prefix - from ' +  ex[0] + ' to ' +  prefixCode);
                                ex[0] = prefixCode
                            }
                        }

                    }

                    //Підставляємо код вибраного кольору, якщо є такий
                    if (store.getters.getEmbroideryColor) {
                        ex[ex.length - 1] = store.getters.getEmbroideryColor.code_option
                    }
                    name = ex.join('_') + '.' + extension

                    urls.push('/storage/' + (img.dir + name).replace(/option/, 'overlay'))
                }

            }
            return urls
        },
        getTranslations(state){
            return state.translations
        }

    },
    mutations: {
        setThereImagesForOption(state, param) {
            state.thereImagesForOption = {
                ...state.thereImagesForOption,
                ...param
            }
        },
        setActiveTab(state, slug) {
            //Тут точки відображались всі на мокапі і при кліку по точці відкривалась вкладка. Зараз це не потрібно сказали
            // document.querySelectorAll('.constructor-box__list-item.selected > .constructor-box__list-head')
            //     .forEach(el => el.click())
            // setTimeout(() => {
            //     let el = document.getElementById('constructor-tab-' + slug)
            //     if (el)
            //         el.click()
            //
            // }, 150)

            if (state.activeTab === slug)
                return state.activeTab = slug = ''

            state.activeTab = slug
        },
        clearDependencies(state, keyItem) {
            for (let key in state.selectedParams) {//перебираємо вибрані опції
                //Дивлюся чи є залежність від якихось опцій
                if (store.getters.getOptionsBySlug[key] && store.getters.getOptionsBySlug[key].option_dependency) {
                    //Дивлюся чи є залежні опції від зміненої, якщо є чистимо
                    if (store.getters.getOptionsBySlug[key].option_dependency.includes(keyItem)) {
                        //Для кольорів
                        if (['Cut', 'Embroidery'].includes(state.selectedParams[key].color_type) && state.selectedParams[key].option_dependency.includes(keyItem)) {
                            store.commit('set' + state.selectedParams[key].color_type + 'Color', null)
                        }
                        store.commit('deleteSelectedParam', store.getters.getOptionsBySlug[key].slug)
                    }
                }
            }
        },
        setMokUp(state, mockup) {
            state.mockup = mockup
        },
        setEditKey(state, key) {
            state.editKey = key
        },
        setMockupsList(state, mockupsList) {
            state.mockupsList = mockupsList

        },
        setCutColor(state, colorCode) {
            state.cutColor = colorCode
        },
        setEmbroideryColor(state, colorCode) {
            state.embroideryColor = colorCode
        },
        setMockupTypes(state, mockupsList) {
            state.mockupsList = mockupsList

        },
        setActiveStep(state, step) {
            state.activeStep = step
        },
        deleteSelectedParam(state, key) {
            for (let k in state.selectedParamsBySteps) {
                if (state.selectedParamsBySteps[k][key])
                    delete state.selectedParamsBySteps[k][key]
            }

            delete state.selectedParams[key]
        },
        setSelectedParam(state, params) {
            state.selectedParamsBySteps[state.activeStep] = {
                ...state.selectedParamsBySteps[state.activeStep],
                ...params
            }
            return state.selectedParams = {
                ...state.selectedParams,
                ...params
            }
        },


        setUrlParams(state) {
            let params = []
            location.search.substr(1).split("&").forEach(function (item) {
                params[item.split("=")[0]] = item.split("=")[1]
            })

            state.urlParams = params
        },
        editProduct(state, product) {
            let selectedParams = {},
                selectedParamsByStep = {
                    Cut: {},
                    Embroidery: {},
                    Checkout: {}
                },
                options = product.options,
                step

            for (let opt in options) {
                if (!options[opt])
                    continue

                step = options[opt].step.capitalize()

                if (step === 'Cut') {
                    selectedParamsByStep[step][options[opt].slug] = options[opt].selected.slug
                    selectedParams[options[opt].slug] = selectedParamsByStep[step][options[opt].slug]
                } else {
                    if (['size', 'label'].includes(options[opt].type)) {
                        selectedParamsByStep[step][options[opt].slug] = options[opt]
                        selectedParams[options[opt].slug] = selectedParamsByStep[step][options[opt].slug]
                    } else {
                        selectedParamsByStep[step][options[opt].slug] = options[opt].selected
                        selectedParams[options[opt].slug] = selectedParamsByStep[step][options[opt].slug]
                    }

                    if (options[opt].type === 'colors') {
                        store.commit('set' + options[opt].selected.color_type + 'Color', options[opt].selected)
                    }
                }
            }

            state.selectedParamsBySteps = selectedParamsByStep
            state.selectedParams = selectedParams
            state.bimp_uuid = product.bimp_uuid ? product.bimp_uuid : '8f298496-431e-4b35-8813-3eec7d347637'
        },
        setTranslations(state, translations){
            state.translations = translations
        },
        setProductId(state, product_id){
            state.productId = product_id
        }
    }
}
