<template>
    <div
        :class="{in: store.getters.openCart}"
        class="modal fade modal-fullwidth" id="modal-cart" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="cart-box">
                    <div class="cart-box__header">
                        <div class="container">
                            <div class="cart-box__header-inner">
                                <div class="cart-box__title">{{$t('cart.title')}}<span class="cart-box__title-badge">{{store.getters.getCartProducts.length}}</span>
                                </div>
                                <a
                                    @click.prevent="store.commit('clearCart')"
                                    class="cart-box__clear" href="/">{{$t('cart.clear')}}</a>
                                <button @click="store.commit('toggleCart')" class="modal-close" type="button">
                                    <span
                                        class="modal-close-label">{{$t('cart.close')}}</span><span
                                    class="modal-close-icon icon-close"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <template>
                        <div
                            v-if="store.getters.getCartProducts.length"
                            class="container">
                            <div class="cart-box__body">
                                <div class="cart-box__list">
                                    <div
                                        v-for="(product, index) in store.getters.getCartProducts"
                                        v-if="product.title"
                                        class="cart-box__list-item">
                                        <div class="product-item">
                                            <div class="product-item__toggle icon-caret-bottom"></div>
                                            <div class="product-item__body">
                                                <div
                                                    @click="store.commit('deleteItemFromCard', index)"
                                                    title="Видалити"
                                                    class="product-item__remove icon-close"></div>
                                                <div class="product-item__image">
                                                    <img class="product-item__image-i"
                                                         :src="!product.previewBase64 ? product.mockup : product.previewBase64"
                                                         role="presentation"/></div>
                                                <div class="product-item__main">
                                                    <span class="product-item__title"
                                                          v-text="translateTitle(product)"></span>
                                                    <div class="product-item__wrap">
                                                        <div
                                                            v-for="opt in chunk(product.options, 3)"
                                                            class="product-item__col">
                                                            <div class="product-item__list">
                                                                <div
                                                                    v-for="optChunk in opt"
                                                                    class="product-item__list-item">
                                                                    <template>
                                                                        <span v-if="optChunk.type === 'colors'"
                                                                              class="product-item__list-label">
                                                                        {{translateColorOption(optChunk)}}:
                                                                        </span>
                                                                        <span v-else class="product-item__list-label" >{{translateOption(optChunk)}}:</span>
                                                                    </template>
                                                                    <span v-if="optChunk.type === 'code'"
                                                                          class="product-item__list-value">{{translateSelectedOption(optChunk)}}</span>
                                                                    <span v-else-if="optChunk.type === 'images'"
                                                                          class="product-item__list-value"><a
                                                                        class="code-link" target="_blank"
                                                                        :href="getSourceUrl(optChunk.selected)">{{optChunk.selected.code_image}}</a></span>
                                                                    <span v-else-if="optChunk.type === 'map'"
                                                                          class="product-item__list-value">{{translateSelectedOption(optChunk)}}</span>
                                                                    <span
                                                                        v-else-if="optChunk.type === 'colors'"
                                                                        class="product-item__list-icon">
                                                                            <img class="product-item__list-icon-i"
                                                                                 :src="'/storage/' + optChunk.selected.dir + optChunk.selected.file_name">
                                                                    </span>
                                                                    <span
                                                                        v-else-if="['size', 'label'].includes(optChunk.type)"
                                                                        class="product-item__list-value"
                                                                        v-text="optChunk.selected"
                                                                    ></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="product-item__col">
                                                            <div class="product-item__list">
                                                                <div class="product-item__list-item"><span
                                                                    class="product-item__list-label">{{$t('order.production')}}:</span><span
                                                                    class="product-item__list-value">{{product.preparation_time}} {{$t('order.days')}}</span>
                                                                </div>
                                                                <div class="product-item__list-item"><span
                                                                    class="product-item__list-label">{{$t('order.price')}}:</span><span
                                                                    class="product-item__price"><span
                                                                    class="product-item__price-value">{{getItemSum(product)}}</span><span
                                                                    class="product-item__price-currency">&#32;₴</span></span>
                                                                </div>
                                                                
                                                            </div>
                                                            <a class="product-item__edit-link"
                                                               :href="getEditUrl(index, product)">{{$t('order.edit')}}</a>
                                                            <div
                                                                v-if="ADMIN">
                                                                <a
                                                                    @click.prevent.stop="addToCatalog(index, product)"
                                                                    class="product-item__edit-link"
                                                                    href="#">
                                                                    + {{$t('order.to_catalog')}}
                                                                </a>
                                                                <a
                                                                    @click.prevent.stop="addToCatalog(index, product, true)"
                                                                    class="product-item__edit-link"
                                                                    href="#">{{$t('order.update')}}
                                                                </a>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="product-item__aside">
                                                <div class="product-item__quantity">
                                                    <div class="quantity-box">
                                                        <div class="quantity-box__label">{{$t('order.products_num')}}:</div>
                                                        <div class="quantity-box__wrap">
                                                            <div
                                                                @click="store.commit('productQntMinus', index)"
                                                                class="quantity-box__control quantity-box__control_minus"></div>
                                                            <input class="quantity-box__field" type="text"
                                                                   :value="product.qnt"
                                                                   maxlength="2"/>
                                                            <div
                                                                @click="store.commit('productQntPlus', index)"
                                                                class="quantity-box__control quantity-box__control_plus"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="product-item__sum">
                                                    <div class="product-item__sum-label">{{$t('order.sum')}}:</div>
                                                    <div class="product-item__sum-wrap"><span
                                                        class="product-item__sum-value">{{getItemSumTotal(product)}}</span><span
                                                        class="product-item__sum-currency">₴</span></div>
                                                    <div class="product-item__sum-dollar" v-if="$i18n.locale != 'uk' ">( ~ {{ToDollar(getItemSumTotal(product))}} USD)</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-else
                            class="container">
                            <div class="cart-box__body">
                                <div class="cart-box__empty">{{$t('cart.empty')}}</div>
                            </div>
                        </div>
                    </template>
                    <div class="cart-box__footer">
                        <div class="container">
                            <div class="cart-box__footer-inner"><a class="cart-box__back-link" :href="'/'+$i18n.locale+'/catalog'">{{$t('cart.back')}}</a>
                                <div
                                    v-if="store.getters.getCartProducts.length > 0"
                                    class="cart-box__footer-aside">
                                    <div class="cart-box__total">
                                        <div class="cart-box__total-label">{{$t('order.totalSum')}}:</div>
                                        <div class="cart-box__total-price">{{totalPrice}} ₴</div>
                                        <div class="product-item__sum-dollar" v-if="$i18n.locale != 'uk' " >( ~ {{ToDollar(totalPrice)}} USD)</div>
                                    </div>
                                    <a class="cart-box__order-link" :href="'/'+$i18n.locale+'/order'"><span
                                        class="cart-box__order-link-label" v-html="$t('cart.order')"></span><span
                                        class="cart-box__order-link-icon icon-arrow-right-2"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Cart",
        data: () => ({
            store: store,
            ADMIN: window.ADMIN,
            colorTitle: [],
        }),
        methods: {
            getSourceUrl(img) {
                if (img.source && img.source.length > 5)
                    return img.source

                return 'https://www.facebook.com/Vytvory'

            },
            ToDollar(sum){
                let rate = store.getters.getExchangeRate;
   
                return (sum/rate).toFixed(2);
            },
            getEditUrl(cartKey, product) {
                return '/constructor/' + product.type + '?m=' + product.mockup_id + '&edit=' + cartKey
            },
            addToCatalog(index, product, update = false) {
                let msg = update ? "Оновити '" + product.title + "' в каталозі?" : "Додати '" + product.title + "' в каталог?"
                if (!confirm(msg))
                    return false

                store.dispatch('addToCatalog', {index, update})
            },
            translateTitle(product){
                const locale = this.$i18n.locale;
            
                if (product.translations){
                    if(locale in product.translations){
                        return product.translations[locale].title
                    }else{
                        return product.title
                    }
                }else{
                    return product.title
                }
            },
            translateOption(option){
                const locale = this.$i18n.locale;
                    if(option.translations){
                        if(locale in option.translations){
                            return option.translations[locale].title;
                        }else{

                            return option.title;
                        }
                    }else{
                        if(['size', 'label'].includes(option.type)){
                            return this.$t('cart.'+option.type)
                        }else{
                            return option.title;
                        }
                    }
                
            },
            translateSelectedOption(option){
                const locale = this.$i18n.locale;
                if(option.translations){
                    if(locale in option.translations){
                        if(option.selected.translations) {
                            return option.selected.translations[locale].title;
                        }
                    }else{
                        return option.selected.title;
                    }
                }else{
                        return option.selected.title;
                }
                
            },
            translateColorOption(option){
                const locale = this.$i18n.locale;
                if(option.translations){
                    if(locale in option.translations){
                        return option.translations[locale].title;
                    }else{
                        return store.getters.getColorTypeTitle[option.selected.color_type];
                    }
                }else{
                        return store.getters.getColorTypeTitle[option.selected.color_type];
                }
            }

        },
        computed: {
            totalPrice() {
                return this.getTotalPrice()
            }
        },
        created() {
            store.commit('initCart')
            store.dispatch('loadExchangeRate');
        }
    }
</script>

<style scoped>
    #modal-cart.in {
        display: block;
        padding-right: 0;
    }
</style>
