<template>
    <div class="constructor-box__list-item">
        <div
            class="constructor-box__list-head collapsed" data-toggle="collapse"
             data-target="#constructor-item-size">
            <div class="constructor-box__list-label required">{{$t('size.title')}}:</div>
            <div v-if="store.getters.getSelectedParamsBySteps.Checkout.size"
                 v-text="getActiveSize"
                 class="constructor-box__list-selected"></div>
            <div v-else class="constructor-box__list-selected">{{$t('size.choose')}}:</div>
            <div class="constructor-box__list-icon"></div>
        </div>
        <div class="constructor-box__list-drop collapse" id="constructor-item-size">
            <div class="constructor-box__list-body">
                <div class="choose-size">
                    <div class="choose-size__aside">
                        <div class="choose-size__item">{{$t('size.params')}}:</div>
                        <div class="choose-size__item">{{$t('size.chest_girth')}}</div>
                        <div class="choose-size__item">{{$t('size.waist_girth')}}</div>
                        <div class="choose-size__item">{{$t('size.hip_girth')}}</div>
                        <div class="choose-size__item">{{$t('size.sleeve_length')}}</div>
                        <div class="choose-size__item">{{$t('size.height1')}}</div>
                        <div class="choose-size__item">{{$t('size.height2')}}</div>
                    </div>
                    <div class="choose-size__main">
                        <div
                            :class="{selected: getActiveSize === 'XXS'}"
                            class="choose-size__col">
                            <div class="choose-size__item">
                                <div
                                    @click="setSize('XXS')"
                                    :class="{active: getActiveSize === 'XXS'}"
                                    class="choose-size__check">XXS
                                </div>
                            </div>
                            <div class="choose-size__item">98,4</div>
                            <div class="choose-size__item">94,7</div>
                            <div class="choose-size__item">99,6</div>
                            <div class="choose-size__item">63,8</div>
                            <div class="choose-size__item">123,1</div>
                            <div class="choose-size__item">59,9</div>
                        </div>
                        <div
                            :class="{selected: getActiveSize === 'XS'}"
                            class="choose-size__col">
                            <div class="choose-size__item">
                                <div
                                    @click="setSize('XS')"
                                    :class="{active: getActiveSize === 'XS'}"
                                    class="choose-size__check">XS
                                </div>
                            </div>
                            <div class="choose-size__item">102,3</div>
                            <div class="choose-size__item">98,5</div>
                            <div class="choose-size__item">103,5</div>
                            <div class="choose-size__item">65,3</div>
                            <div class="choose-size__item">123,5</div>
                            <div class="choose-size__item">60,3</div>
                        </div>
                        <div
                            :class="{selected: getActiveSize === 'S'}"
                            class="choose-size__col">
                            <div class="choose-size__item">
                                <div class="choose-size__check"
                                     @click="setSize('S')"
                                     :class="{active: getActiveSize === 'S'}"
                                >S
                                </div>
                            </div>
                            <div class="choose-size__item">106,1</div>
                            <div class="choose-size__item">102,3</div>
                            <div class="choose-size__item">107,3</div>
                            <div class="choose-size__item">66,7</div>
                            <div class="choose-size__item">124,0</div>
                            <div class="choose-size__item">60,7</div>
                        </div>
                        <div
                            :class="{selected: getActiveSize === 'M'}"
                            class="choose-size__col">
                            <div class="choose-size__item">
                                <div
                                    @click="setSize('M')"
                                    :class="{active: getActiveSize === 'M'}"
                                    class="choose-size__check">M
                                </div>
                            </div>
                            <div class="choose-size__item">110,0</div>
                            <div class="choose-size__item">106,1</div>
                            <div class="choose-size__item">111,1</div>
                            <div class="choose-size__item">68,2</div>
                            <div class="choose-size__item">124,4</div>
                            <div class="choose-size__item">61,1</div>
                        </div>
                        <div
                            :class="{selected: getActiveSize === 'L'}"
                            class="choose-size__col">
                            <div class="choose-size__item">
                                <div
                                    @click="setSize('L')"
                                    :class="{active: getActiveSize === 'L'}"
                                    class="choose-size__check">L
                                </div>
                            </div>
                            <div class="choose-size__item">113,8</div>
                            <div class="choose-size__item">109,9</div>
                            <div class="choose-size__item">115,0</div>
                            <div class="choose-size__item">69,6</div>
                            <div class="choose-size__item">124,9</div>
                            <div class="choose-size__item">61,4</div>
                        </div>
                        <div
                            :class="{selected: getActiveSize === 'XL'}"
                            class="choose-size__col">
                            <div class="choose-size__item">
                                <div
                                    @click="setSize('XL')"
                                    :class="{active: getActiveSize === 'XL'}"
                                    class="choose-size__check">XL
                                </div>
                            </div>
                            <div class="choose-size__item">117,7</div>
                            <div class="choose-size__item">113,7</div>
                            <div class="choose-size__item">118,8</div>
                            <div class="choose-size__item">71,0</div>
                            <div class="choose-size__item">125,3</div>
                            <div class="choose-size__item">61,8</div>
                        </div>
                        <div
                            :class="{selected: getActiveSize === 'XXL'}"
                            class="choose-size__col">
                            <div class="choose-size__item">
                                <div
                                    @click="setSize('XXL')"
                                    :class="{active: getActiveSize === 'XXL'}"
                                    class="choose-size__check">XXL
                                </div>
                            </div>
                            <div class="choose-size__item">121,5</div>
                            <div class="choose-size__item">117,4</div>
                            <div class="choose-size__item">122,6</div>
                            <div class="choose-size__item">72,5</div>
                            <div class="choose-size__item">125,8</div>
                            <div class="choose-size__item">62,2</div>
                        </div>
                        <div
                            :class="{selected: getActiveSize === 'XXXL'}"
                            class="choose-size__col">
                            <div class="choose-size__item">
                                <div
                                    @click="setSize('XXXL')"
                                    :class="{active: getActiveSize === 'XXXL'}"
                                    class="choose-size__check">XXXL
                                </div>
                            </div>
                            <div class="choose-size__item">125,4</div>
                            <div class="choose-size__item">121,2</div>
                            <div class="choose-size__item">126,4</div>
                            <div class="choose-size__item">73,9</div>
                            <div class="choose-size__item">126,2</div>
                            <div class="choose-size__item">62,6</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DynamicOption from '../../classes/DynamicOption'

    let match = window.location.href.split("/")[3];
    export default {
        name: "Size",
        data: () => ({
            store: store,
            i18n: i18n
        }),
        methods: {
            setSize(val) {
                let size = new DynamicOption('size', val, (match == 'en') ? '"Size"' : '"Розмір"')
                size.slug = 'size'
                store.commit('setSelectedParam', {size})
            }
        },
        computed: {
            getActiveSize() {
                return store.getters.getSelectedParamsBySteps.Checkout.size ?
                    store.getters.getSelectedParamsBySteps.Checkout.size.value : ''
            }
        }
    }
</script>
