/*
 * Клас динамічної оопції, яку немає сенсу зберігати в Бд
 */
"use strict";
export default class DynamicOption {
    constructor(type, value = 0, title = 'Динамічна опція') {
        this.title = title
        this.value = value
        this.type = type
        this.price = 0
        this.code = ''
        this.slug = ''
        this.step = 'Checkout'
    }
}
